import { useQuery, gql, useMutation } from '@apollo/client';
import { ExternalAccount, Profile as ProfileType } from '~types';
import { FRAGMENT_EXTERNAL_ACCOUNT_FILEDS } from '../../hooks/useGetExternalAccounts';

export const UPDATE_TRADE_URL = gql`
  mutation UpdateTradeUrl($tradeUrl: String!) {
    updateTradeUrl(tradeUrl: $tradeUrl) {
      id
      tradeUrl
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($email: String!) {
    updateEmail(email: $email) {
      id
      email
    }
  }
`;

export const GET_PROFILE_STATS = gql`
  query getProfileStats {
    getProfileStats {
      defaultGamesCount
      contractGamesCount
      upgradeGamesCount
      daysOnSite
    }
  }
`;

export const GET_USER_STATS = gql`
  query getUserStats($id: Int!) {
    getUserStats(id: $id) {
      defaultGamesCount
      contractGamesCount
      upgradeGamesCount
      daysOnSite
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      deposit24Hour
      lastFreeGameDate
      deposit24HourTtl
      userName
      userId
      avatar
      social
      money
      battlePassCoins
      coins
      group
      isPay
      createdAt
      tradeUrl
      accessFreeCase
      inputMoney
      bonusCode
      referralMoney
      country
      language
      isPlayEvent
      createdFormat @client
      email
      phone
      getWheelStep
      joinedToGroup
      joinedToGroupTelegram
      joinedToTwitter
      joinedToFacebook
      getDistributionsPaymentConditions
      getExternalAccounts {
        ...ExternalAccountFields
      }
      getSteam @client
      getVk @client
      getTelegram @client
      gaTestId
      isBanned
    }
  }

  ${FRAGMENT_EXTERNAL_ACCOUNT_FILEDS}
`;

type Profile = ProfileType & {
  createdFormat?: string;
  getSteam?: ExternalAccount;
  getVk?: ExternalAccount;
  getTelegram?: ExternalAccount;
};

interface UserProfileData {
  getProfile: Profile;
}

type ProfileStats = {
  defaultGamesCount: number;
  contractGamesCount: number;
  upgradeGamesCount: number;
  daysOnSite: number;
};

interface UseProfileStats {
  getProfileStats: ProfileStats;
}

type UserStats = {
  defaultGamesCount: number;
  contractGamesCount: number;
  upgradeGamesCount: number;
  daysOnSite: number;
};

interface UserUserStats {
  getUserStats: UserStats;
}

export const useProfile = () => {
  const { data } = useQuery<UserProfileData>(GET_PROFILE);

  return data?.getProfile;
};

export const useProfileStats = () => {
  const { data } = useQuery<UseProfileStats>(GET_PROFILE_STATS);
  return data?.getProfileStats;
};

export const useUserStats = (id: number) => {
  const { data } = useQuery<UserUserStats>(GET_USER_STATS, {
    variables: { id },
  });
  console.log('datta111', data);
  return data?.getUserStats;
};

export const useUpdateTradeUrl = (tradeUrl: string) => {
  return useMutation(UPDATE_TRADE_URL, {
    variables: { tradeUrl },
  });
};

export const useUpdateEmail = (email: string) => {
  return useMutation(UPDATE_EMAIL, {
    variables: { email },
  });
};
