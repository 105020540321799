import { FunctionComponent, SyntheticEvent } from 'react';
import './luckyModalStyles.scss';
import winImg from '../../../scss/images/win.webp';
import { SteamItemImage } from '~frontend/components/item';
import { useConfig, useProfile } from '~frontend/components/hooks';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from '../hooks';
import { OpenCase as OpenCaseTypes } from '~types';
import Modal from '../Modal';
import Currency from '~frontend/components/Currency';
import { Price } from '~shared/frontend/ui';
import { useStatusGame } from '~frontend/components/play/hooks';
import GameChange from '~frontend/components/game/GameChange';
import { Button } from '~shared/frontend/ui/button/button';
import ModalWrapper from '../mobile-filter/modal-wrapper/modalWrapper';

export const LuckyModalWin: FunctionComponent = () => {
  const getProfile = useProfile();
  const getConfig = useConfig();
  const modalName = 'LuckyWinModal';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const [statusGame, setStatusGame] = useStatusGame();
  const { onClose, isOpen, props } = useModal<{ getGame: OpenCaseTypes }>(
    modalName,
  );

  console.log('show');

  const openCase = props?.getGame;
  const getGame = openCase?.getGame;
  const getItem = getGame?.getItem;

  const onSetGame = (event: SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }

    setStatusGame(0);
    onClose();
  };

  return (
    <Modal show={isOpen} onClose={onClose} width={440} modalName={modalName}>
      <ModalWrapper isOpen={isOpen} onClose={onClose} title={'Lucky Drop'}>
        {' '}
        <div className="lucky_modal">
          <div className="lucky_modal_title">
            <img src={winImg} alt="win" width={32} height={32} />
            А ты везунчик!
            <img src={winImg} alt="win" width={32} height={32} />
          </div>

          <div className="demo_modal_prize">
            <div className="demo_modal_prize_header">
              <div className="demo_modal_prize_price">
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getGame?.price} />
                  </>
                ) : (
                  <>
                    <Price sum={getGame?.price} />
                    <Currency />
                  </>
                )}
              </div>
              <div className="demo_modal_prize_percent">{getGame?.chance}</div>
            </div>
            <SteamItemImage
              src={getItem?.getImage}
              size="218x164"
              alt="prize"
              width={218}
              height={164}
              className="demo_modal_prize_image"
            />
            <div className="demo_modal_prize_info">
              <div className="demo_modal_prize_tag">
                {' '}
                {getItem?.getName.split('|')[0].trim()}
              </div>
              <div className="demo_modal_prize_name">
                <span>{getItem?.quality}</span> |{' '}
                {getItem?.getName.split('|')[1].trim()}
              </div>
            </div>
          </div>

          <div className="demo_modal_buttons">
            <GameChange
              getGame={getGame}
              type={0}
              className="btn1 btn1_green lucky_modal_button"
              onDone={onSetGame}
              //onClick={onClose}
            >
              {' '}
              <span>
                {t('Sell for')}{' '}
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getItem?.price} />
                  </>
                ) : (
                  <>
                    <Price sum={getItem?.price} />
                    <Currency />
                  </>
                )}
              </span>
            </GameChange>

            <Button
              text={'В апгрейды'}
              data-event="goUpgrade"
              href={'/upgrades'}
              isOutterHref={false}
              disabled={true}
              state={{ gameIds: [getGame?.id] }}
              className={`btn1_dark_bordered lucky_modal_button_dark`}
            />

            <Button
              text={t('add_to_contract')}
              data-event="goContract"
              href={'/contract'}
              disabled={true}
              isOutterHref={false}
              state={{ gameIds: [getGame?.id] }}
              className={`btn1_dark_bordered lucky_modal_button_dark`}
            />
          </div>
        </div>
      </ModalWrapper>
    </Modal>
  );
};
